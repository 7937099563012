import React, { useState, useEffect } from "react";
import ky from "ky";
import {
  FormContainer,
  TextFieldElement,
  DatePickerElement,
} from "react-hook-form-mui";

import CreateEventStepper from "../Components/CreateEventStepper";
import toast, { Toaster } from "react-hot-toast";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '70%',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EventsAdmin() {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const bearer = process.env.REACT_APP_API_BEARER;
  const [dataValues, setDataValues] = useState([]);

  const [formValues, setFormValues] = React.useState({});
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleOpenInfoModal = (params) => {
    setInfoModalOpen(true);
    setSelectedRowData(params);
  };

  const handleAddModalOpen = () => setAddModalOpen(true);

  const handleEdit = (params) => {
    setEditMode(true);
    setAddModalOpen(true);
  };


  const handleClose = () => {
    setEditMode(false);
    setAddModalOpen(false);
    setInfoModalOpen(false);
  };

  const handleCreateEventSuccess = (message) => {
    setAddModalOpen(false);
    toast.success(
      message
    );
    fetchData();
  };


  const columns = [
    {
      field: "id",
      headerName: "Edit",
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleEdit(params.row)}>
          Edit
        </Button>
      ),
    },
    { field: "name", headerName: "Name", width: 200 },
    { field: "description", headerName: "Description", width: 500 },
    {
      field: "games",
      headerName: "Games",
      sortable: true,
      width: 100,
      valueGetter: (params) =>
        `${params.row.games ? params.row.games.length : 0}`,
    },
    { field: "curator", headerName: "Curator", width: 200 },
    { field: "created_at", headerName: "Created", width: 200 },
    { field: "isActive", headerName: "Active?", width: 200 },
  ];

  const fetchData = async () => {
    const data = await ky
      .get(`${baseUrl}/event`, {
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
      .json();
      
    setDataValues(Object.values(data.data));
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e);
    } 
  }, []);

  return (
    <Box sx={{ height: 300 }}>
      <Button variant="contained" onClick={handleAddModalOpen}>
        Create New Set
      </Button>
      <Box sx={{ pb: 2 }} />
      {dataValues.length ? (
        <>
          <DataGrid
            rows={dataValues}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            onRowClick={(params) => handleOpenInfoModal(params.row)}
            disableRowSelectionOnClick
          />
          <Modal
            open={infoModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {selectedRowData && (
                <>
                  <div>Name: {selectedRowData.name}</div>
                  <div>Description: {selectedRowData.description}</div>
                  <div>Curator: {selectedRowData.curator}</div>
                  <div>
                    <ul>
                      {selectedRowData.games && selectedRowData.games.map((game) => (
                        <li key={game.id}>
                          {game.homeTeamName} vs. {game.awayTeamName} (
                          {game.homeTeamScore} - {game.awayTeamScore})
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </Box>
          </Modal>
          <Modal
            open={addModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CreateEventStepper
                editMode={editMode}
                selectedRowData={selectedRowData}
                isOpen={addModalOpen}
                setAddModalOpen={handleCreateEventSuccess}
              />
            </Box>
          </Modal>
        </>
      ) : (
        <div>Loading data...</div>
      )}
    </Box>
  );
}

export default EventsAdmin;