import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

// import Login from "./Login";

import { view } from "@risingstack/react-easy-state";

const Footer = () => {
  return (
      <Container sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>
        <Typography variant="caption" component="div" gutterBottom>
          <Link href="mailto:help@pickery.io">Pickery.io</Link>, 2022.
        </Typography>
      </Container>
  );
};
export default view(Footer);
