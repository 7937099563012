import React, { useState, useEffect } from "react";
import ky from "ky";
import {
  FormContainer,
  TextFieldElement,
  DatePickerElement,
} from "react-hook-form-mui";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";

import "./../App.css";
import EventsAdmin from "../Components/EventsAdmin";
import NewsEditor from "../Screens/NewsEditor";

const columns = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "homeTeamName", headerName: "Home Team", width: 200 },
  { field: "awayTeamName", headerName: "Away Team", width: 200 },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 300,
    valueGetter: (params) =>
      `${params.row.homeTeamName || ""} vs. ${params.row.awayTeamName || ""}`,
  },
];

const rows = [
  { id: 1, homeTeamName: "Snow", awayTeamName: "Jon" },
  { id: 2, homeTeamName: "Lannister", awayTeamName: "Cersei" },
  { id: 3, homeTeamName: "Snow", awayTeamName: "Jon" },
  { id: 4, homeTeamName: "Lannister", awayTeamName: "Cersei" },
  { id: 5, homeTeamName: "Snow", awayTeamName: "Jon" },
  { id: 6, homeTeamName: "Lannister", awayTeamName: "Cersei" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Fixtures() {
  return <Box sx={{ height: 400, width: "100%" }}>Fixtures</Box>;
}

function Admin() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", m: 2, p: 2 }}>
      <Tabs value={value} onChange={handleChange} aria-label="admin panel tabs">
        <Tab label="Events" />
        <Tab label="Fixtures" />
        <Tab label="Users" />
        <Tab label="News" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <EventsAdmin />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Fixtures />
      </TabPanel>
      <TabPanel value={value} index={2}>
        Users
      </TabPanel>
      <TabPanel value={value} index={3}>
        <NewsEditor />
      </TabPanel>
    </Box>
  );
}

export default Admin;
