import React, { useState, useEffect } from "react";
import ky from "ky";
import TinderCard from "react-tinder-card";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { view } from "@risingstack/react-easy-state";
import { storage } from "react-easy-params";
import { useParams } from "react-router-dom";

import "./../App.css";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;

function Profile() {
  let { userID } = useParams();
  const [data, setData] = useState([]);

  if (!userID) {
    userID = storage.user.userObject.user_id;
  }

  useEffect(() => {
    (async () => {
      const userData = await ky
        .get(`${baseUrl}/users/${userID}`, {
          headers: {
            Authorization: `Bearer ${storage.user.userObject.token}`,
            "Content-Type": "application/json",
          },
        })
        .json();
      const leaderboardData = await ky
        .get(`${baseUrl}/leaderboards/`, {
          headers: {
            Authorization: `Bearer ${storage.user.userObject.token}`,
            "Content-Type": "application/json",
          },
        })
        .json();
      const filteredLeaderboard = leaderboardData[0].entries.filter(
        (entry) => entry.userID === userID
      );
      setData({ userData, filteredLeaderboard });
    })();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          m: 2,
          p: 2,
          width: "80%"
        }}
      >
        <Typography variant="h4">Name: <Typography as="span" variant="h4" color="yellow">{data.userData ? data.userData.name : ''}</Typography></Typography>
        <Typography variant="h6">Best Streak: <Typography as="span" variant="h6" color="yellow">{data.userData ? data.userData.historyStreak : ''}</Typography></Typography>

          {data.filteredLeaderboard &&
            data.filteredLeaderboard
              .slice(0, 10)
              .map((entry, index) => (
                <Box key={entry._id}>
                  <Typography variant="p" color="yellow" as="span">
                    {index + 1}.
                  </Typography>

                  <Typography variant="p" as="span">
                    {entry.score} points -
                  </Typography>
                  <Typography variant="p" as="span" color="yellow">
                     {` ${Date(entry.timestamp).slice(0,21)}`}
                  </Typography>
                </Box>
              ))}
      </Box>
    </>
  );
}

export default view(Profile);
