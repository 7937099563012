import React, { useState, useEffect } from "react";
import ky from "ky";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import "./../App.css";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;

function Leaderboards() {
  const [dataValues, setDataValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await ky
        .get(`${baseUrl}/leaderboard`, {
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        })
        .json();
      setDataValues(data);
    };

    try {
      fetchData();
    } catch (e) {
      console.log(e);
    } finally {
      console.log(dataValues);
    }
  }, []);

  return (
    <Box
      sx={{
        m: 2,
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h5" color="orange" component="div" gutterBottom>
          {`TOP TEN`}
        </Typography>
      </Box>
      {dataValues.length > 0 &&
        dataValues
          .sort((a, b) => (a.score < b.score ? 1 : -1))
          .slice(0, 10)
          .map((entry, index) => (
            <Box key={entry.id}>
              <Typography variant="p" color="yellow" as="span">
                {index + 1}.
              </Typography>
              {entry.id ? (
                <Link underline="hover" href={`profile/${entry.userID}`}>
                  {entry.username.toUpperCase()}
                </Link>
              ) : (
                <Typography variant="p" as="span">
                  {entry.username.toUpperCase()}
                </Typography>
              )}

              <Typography variant="p" as="span">{` - `}</Typography>
              <Typography variant="p" as="span">
                {entry.score}
              </Typography>
            </Box>
          ))}
    </Box>
  );
}

export default Leaderboards;
