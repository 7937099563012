import React, { useState, useEffect, useCallback } from "react";
import ky from "ky";
import { view, store } from "@risingstack/react-easy-state";
import appStore from "./../utils/appStore";
import { storage } from "react-easy-params";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import html2canvas from "html2canvas";
import Paper from "@mui/material/Paper";
import Gradient from "rgt";
import ConfettiExplosion from "react-confetti-explosion";

import "./../App.css";
import TinderCardDisplay from "./../Components/TinderCard";
import Scoreboard from "./../Components/Scoreboard";
import toast from "react-hot-toast";

import ReactGA from "react-ga4";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const mediumExplodeProps = {
  force: 0.5,
  duration: 2500,
  particleCount: 250,
  particleSize: 10,
  floorHeight: 1200,
  floorWidth: 600,
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  fontFamily: "theme.typography.scoreboard",
};

const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;

function QuizGame() {
  const shareModalData = store({ name: "ABC", score: 0 });
  const [isExploding, setIsExploding] = React.useState(true);
  const [countdownOn, setCountdownOn] = useState(true);
  const [countdownOnString, setCountdownOnString] = useState("READY");

  const [dataValues, setDataValues] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const handleOpenShareModal = () => setShareModalOpen(true);

  const handleShareModalClose = () => {
    setShareModalOpen(false);
    shareModalData.name = "ABC";
    shareModalData.score = 0;
    resetGame();
  };

  const [lastDirection, setLastDirection] = useState();

  const defaultValues = {
    username: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      [name]: value,
    });
  };

  const swiped = async (direction, match, index) => {
    let guess;

    if (["up", "down"].includes(direction)) {
      return false;
    }

    switch (direction) {
      case "right":
        guess = "HOME_TEAM";
        break;
      case "left":
        guess = "AWAY_TEAM";
        break;
      case "up":
        guess = "DRAW";
        break;
      default:
        guess = "DRAW";
    }

    if (guess === match.winner) {
      appStore.scoreboard.correctToast();
    } else {
      appStore.scoreboard.incorrectToast();
    }
    setCurrentIndex(index);
    setLastDirection(direction);
    if (index === 0) {
      await fetchData();
    }
  };

  const fetchData = async () => {
    const data = await ky
      .get(`${baseUrl}/feed`, {
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        }
      })
      .json();
      console.log(data)
    await setDataValues(data);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e);
    } finally {
      console.log(dataValues)
      window.analytics.track('game_started', {
        type: "quiz",
        restart: false
      });
    }
  }, []);

  const callback = useCallback(
    (dir, match, index) => {
      swiped(dir, match, index);
    },
    [swiped]
  );

  const resetGame = async () => {
    setCountdownOn(true);
    await fetchData();
    appStore.scoreboard.reset();
    window.analytics.track('game_started', {
      type: "quiz",
      restart: true
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitScore();
  };

  const submitScore = async () => {
    let username;
    if (formValues.username.length < 3) {
      username = "ABC";
    } else {
      username = formValues.username;
    }
    try {

      const userID = storage.user.userObject ? storage.user.userObject.user_id : null

      const requestBody = {
        score: appStore.scoreboard.currentStreak,
        username: username,
      };

      await ky
        .post(`${baseUrl}/leaderboard`, {
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        })
        .json();
        
    } catch (e) {
      console.log(e);
    } finally {
      shareModalData.name = username;
      shareModalData.score = appStore.scoreboard.currentStreak;
      window.analytics.track('submit_score', {
        type: "quiz",
        score: appStore.scoreboard.streak
      });
      handleClose();
      handleOpenShareModal();
    }
  };

  return (
    <>
      <Box>
        {countdownOn ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "column",
              mt: "30vh",
            }}
          >
            <Typography sx={{ mb: 4 }} variant="h4">
              {countdownOnString}
            </Typography>
            <CountdownCircleTimer
              strokeWidth={1}
              isPlaying={countdownOn}
              key={countdownOn}
              size={"100"}
              onComplete={() => setCountdownOn(false)}
              duration={3}
              colors={["#FF0000", "#FFFF00", "#89CFF0"]}
              colorsTime={[3, 2, 1]}
              onUpdate={(remainingTime) => {
                switch (remainingTime) {
                  case 3:
                    setCountdownOnString("READY TO PICK?");
                    break;
                  case 2:
                    setCountdownOnString("ALMOST THERE...");
                    break;
                  case 1:
                    setCountdownOnString("SET...");
                    break;
                  default:
                    setCountdownOnString("SET...");
                }
              }}
            >
              {({ remainingTime }) => <Typography>{remainingTime}</Typography>}
            </CountdownCircleTimer>
          </Box>
        ) : (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              m: 2,
            }}
          >
            <Item
              sx={{ display: "flex", justifyContent: "center" }}
              xs={24}
              md={12}
            >
              <Scoreboard data={{}} />
            </Item>

            <Item
              sx={{
                height: "50vh",
                mt: 2,
                display: "flex",
                justifyContent: "center",
              }}
              xs={24}
              md={12}
            >
              {dataValues.length > 0 &&
                dataValues.map((match, index) => (
                  <TinderCardDisplay
                    parentCallback={callback}
                    key={match.id}
                    data={{ match: match, index: index }}
                  />
                ))}
              {appStore.scoreboard.timeExpired && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>
                    <Typography variant="h6" color="red">
                      Time's Up.
                    </Typography>
                  </Item>
                  {appStore.scoreboard.currentStreak === 0 ? null : (
                    <Item>
                      <Typography variant="p">
                        <Typography variant="span" color={"yellow"}>
                          {appStore.scoreboard.currentStreak}
                        </Typography>{" "}
                        is a nice score!
                      </Typography>
                    </Item>
                  )}

                  <Item>
                    <Typography variant="body">
                      Try again, or save your high score!
                    </Typography>
                  </Item>
                  <Item>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        disabled={!appStore.scoreboard.currentStreak > 0}
                        variant="contained"
                        color="success"
                        onClick={handleOpen}
                      >
                        SUBMIT SCORE {appStore.scoreboard.currentStreak}
                      </Button>
                      <Button color="warning" onClick={() => resetGame()}>
                        START OVER
                      </Button>
                    </Box>
                  </Item>
                </Grid>
              )}
            </Item>
          </Grid>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // backgroundColor: "pink",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  inputProps={{
                    maxLength: 3,
                    step: 300,
                    style: {
                      fontSize: "5rem",
                      display: "flex",
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: "yellow",
                      fontFamily: "'Press Start 2P', cursive",
                    },
                  }}
                  autoComplete="no"
                  name={`username`}
                  value={formValues.username}
                  onChange={handleInputChange}
                  autoFocus={true}
                  label="Name"
                  variant="filled"
                />

                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Modal
          open={shareModalOpen}
          onClose={handleShareModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} onClick={() => setIsExploding(!isExploding)}>
            <Typography variant="h5" align="center" sx={{ mb: 2 }}>
              <Gradient dir="left-to-right" from="yellow" to="#007CF0">
                PICKERY
              </Gradient>
            </Typography>
            <Typography align="center" variant="h5" sx={{ mb: 2 }}>
              🎉 Woohoo!🎉
            </Typography>{" "}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <>
                {isExploding && <ConfettiExplosion {...mediumExplodeProps} />}
              </>
            </Box>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              <Typography color="yellow" as="span" variant="body2">
                {shareModalData.name.toUpperCase()}
              </Typography>{" "}
              is on the board.
            </Typography>{" "}
            <Typography variant="body2">
              <Typography color="yellow" as="span" variant="body2">
                {shareModalData.score}
              </Typography>{" "}
              points
            </Typography>
            <Typography variant="body2">
              <Typography color="yellow" as="span" variant="body2">
                {appStore.scoreboard.currentTripleCount}
              </Typography>{" "}
              triples.
            </Typography>
            <Typography variant="body2">
              on{" "}
              <Typography color="yellow" as="span" variant="body2">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                })
                  .format(Date.now())
                  .toLowerCase()}
              </Typography>
            </Typography>
            <div
              data-html2canvas-ignore
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></div>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default view(QuizGame);
