import React, { useState, useEffect } from "react";
import ky from "ky";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";


const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;

function NewsEditor() {
  const [dataValues, setDataValues] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [content, setContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);

    const fetchData = async () => {
    const data = await ky
        .get(`${baseUrl}/news?all`, {
        headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
        },
        })
        .json();
    setDataValues(data);
    };

  useEffect(() => {
    try {
      fetchData();
    } catch (e) {
      console.log(e);
    } finally {
        console.log(dataValues)
    }
  }, []);

const handleSubmit = async (event) => {
  event.preventDefault();

  if (isEditing) {
    await ky.post(`${baseUrl}/news`, {
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
      json: { content, isEditing: true, id: selectedArticle.id },
    });
  } else {
    await ky.post(`${baseUrl}/news`, {
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
      json: { content, isEditing: false },
    });
  }

  setContent("");
  setIsEditing(false);
  setSelectedArticle(null);
  fetchData();
};

  const handleEdit = (article) => {
    setSelectedArticle(article);
    setContent(article.content);
    setIsEditing(true);
  };

    const handleDelete = async (article) => {
        await ky.post(`${baseUrl}/news`, {
            headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
            },
            json: { isActive: !article.isActive, id: article.id, isEditing: true, content: article.content },
        });

        fetchData();
    };


  const columns = [
    { field: "id", headerName: "id", width: 20 },
    { field: "content", headerName: "Content", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit(params.row)}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Active?",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleDelete(params.row)}>
          {params.row.isActive ? "Disable" : "Enable"}
        </Button>
      ),
    },
  ];

  const rows = dataValues

  return (
    <Box sx={{ width: "100%", m: 2, p: 2}}>
      <Box sx={{ mt: 2, width: "100%" }}>
        <Typography variant="h5" component="div" gutterBottom>
          {isEditing ? "Edit Article" : "Add Article"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            multiline
            label="Content"
            variant="outlined"
            value={content}
            onChange={(event) => setContent(event.target.value)}
            sx={{ width: "100%", margin: "auto" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              marginTop: 2,
            }}
          >
            <Button type="submit">{isEditing ? "Update" : "Submit"}</Button>{" "}
            <Button
              type="reset"
              onClick={() => {
                setContent("");
                setIsEditing(false);
              }}
            >
              Reset
            </Button>
          </Box>
        </form>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" component="div" gutterBottom>
          Existing Articles:
        </Typography>
        <DataGrid rows={rows} columns={columns} autoHeight />
      </Box>
    </Box>
  );
}

export default NewsEditor;
