import * as React from "react";
import ky from "ky";
import appStore from "./../utils/appStore";
import { view } from "@risingstack/react-easy-state";
import { storage } from "react-easy-params";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import GoogleIcon from '@mui/icons-material/Google';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBieYLky3D2Hg7JpBvEXJ0et-wLWvUYQkQ",
  authDomain: "pickery-v2.firebaseapp.com",
  databaseURL: "https://pickery-v2.firebaseio.com",
  projectId: "pickery-v2",
  storageBucket: "pickery-v2.appspot.com",
  messagingSenderId: "1066149870504",
  appId: "1:1066149870504:web:8d36b52d7afa64c1417b9d",
};

firebase.initializeApp(firebaseConfig);

const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;


const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.default",
  boxShadow: 24,
  p: 4,
  display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  flexDirection: "column",
  fontFamily: "theme.typography.scoreboard",
};

const Login = () => {
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const googleAuth = () => {
    appStore.user.googleAuth()
    handleCloseModal()
  }

  const signOut = () => {
    appStore.user.signOut()
    navigate("/")
  }

  return (
    <>
    <Box>
      {!storage.user.isAuthenticated ? (
        <Button variant="text" onClick={handleOpenModal}>Join/Login</Button>
      ) : (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button variant="text" onClick={signOut}>Sign Out</Button>
          </Box>
      )}
    </Box>
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>


      <Typography sx={{mb: 2}} variant="h3">Why Join?</Typography>
      <Typography variant="subtitle2" color="yellow">- Own your scores & come back any time.</Typography>
      <Typography variant="subtitle2" color="yellow">- Curated member-only events.*</Typography>
      <Typography variant="subtitle2" color="yellow">- Opt out of sports you don't like.*</Typography>
      <Typography variant="subtitle2" color="yellow">- It's super FREE.</Typography>

      <Typography sx={{mt: 2}} variant="caption">You can join with your Google account by clicking below. Pickery is <Typography as="span" variant="caption" color="yellow">free</Typography> and you will be able to delete your account later.</Typography>
      <Typography sx={{mt: 2}} variant="caption">* Pickery is in active development. More features are in progress.</Typography>

        <Button variant="outlined" onClick={googleAuth} startIcon={<GoogleIcon/>}>
          {`Continue with Google`}
        </Button>
      </Box>
    </Modal>
    </>
  );
};
export default view(Login);
