import React, { useState, useEffect } from "react";
import ky from "ky";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import { FormControlLabel, Switch } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import BasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsFootball from "@mui/icons-material/SportsFootball";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import SportsBaseballSharpIcon from "@mui/icons-material/SportsBaseballSharp";

import { set } from "date-fns";
import { fontSize } from "@mui/system";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;

export default function VerticalForm({
  isOpen,
  setAddModalOpen,
  editMode,
  selectedRowData,
}) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [eventName, setEventName] = React.useState(editMode ? selectedRowData.name : "");
  const [eventDescription, setEventDescription] = React.useState(editMode ? 
    selectedRowData.description : ""
  );
  const [eventIsActive, setEventIsActive] = React.useState(editMode ? selectedRowData.isActive : true)
  const [gamesList, setGamesList] = React.useState(
    editMode && selectedRowData.games ? selectedRowData.games : []
  );

  const [newGamesList, setNewGamesList] = React.useState([])
  const [newGameEventTypes, setNewGameEventTypes] = useState([
    {
      label: "Football",
      value: "football",
      icon: <SportsSoccerIcon />,
    },
    {
      label: "Soccer",
      value: "soccer",
      icon: <SportsFootball />,
    },
    {
      label: "Basketball",
      value: "basketball",
      icon: <BasketballIcon />,
    },
    {
      label: "Wrestling",
      value: "wrestling",
      icon: <SportsKabaddiIcon />,
    },
    {
      label: "Baseball",
      value: "baseball",
      icon: <SportsBaseballSharpIcon />,
    },
  ]);
  const [selectedEventType, setSelectedEventType] = React.useState(null)

  const [selectedGame, setSelectedGame] = React.useState(null);
  const [selectedSport, setSelectedSport] = React.useState(null);
  const [availableGames, setAvailableGames] = React.useState([]);
  const [dataValues, setDataValues] = useState([]);

  const [loading, setLoading] = useState(true);

  const [isAddingNewGame, setIsAddingNewGame] = useState(false);
  const [homeTeamName, setHomeTeamName] = useState("");
  const [awayTeamName, setAwayTeamName] = useState("");
  const [homeTeamShortName, setHomeTeamShortName] = useState("");
  const [awayTeamShortName, setAwayTeamShortName] = useState("");
  const [awayTeamScore, setAwayTeamScore] = useState(0)
  const [homeTeamScore, setHomeTeamScore] = useState(0)
  const [gameDate, setGameDate] = useState(new Date());
  const [winner, setWinner] = useState("");


  const fetchData = async (eventType) => {
    setLoading(true);

    let url = "";

    if (eventType) {
      url = `${baseUrl}/games?eventType=${eventType}`;
    } else {
      url = `${baseUrl}/games`;
    }

    const data = await ky
      .get(url, {
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
      .json();
    setDataValues(data.data);
    setAvailableGames(data.data);
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchData();
      
    } catch (e) {
      console.log(e);
    } finally {
      console.log({ data: dataValues });
    }
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddGame = () => {
    setGamesList([...gamesList, selectedGame]);
    setAvailableGames((prevGames) => {
      const filteredGames = prevGames.filter(
        (game) => game.id !== selectedGame.id
      );
      return filteredGames;
    });
    setSelectedGame(null);
  };

  const handleRemoveGame = (game) => {
    const updatedGames = gamesList.filter((g) => g.id !== game.id);
    const updatedNewGames = newGamesList.filter((g) => g !== game)
    setGamesList(updatedGames);
    setNewGamesList(updatedNewGames)
  };

  const filterBySport = (sport) => {
    setSelectedGame(null);
    setSelectedSport(sport);
    fetchData(sport);
    setAvailableGames(dataValues);
  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    const data = {
      eventName,
      eventDescription,
      gamesList,
      newGamesList,
      editMode,
      awayTeamScore,
      homeTeamScore
    };

    const transformedData = {
      name: data.eventName,
      description: data.eventDescription,
      games: data.gamesList.map((game) => game.id),
      newGames: data.newGamesList,
      editMode: editMode,
      eventID: editMode ? selectedRowData.id : null,
      isActive: eventIsActive,
      homeTeamScore: data.homeTeamScore,
      awayTeamScore: data.awayTeamScore
    };

    try {
      const response = await fetch(`${baseUrl}/events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transformedData),
      });

      if (response.ok) {
        const responseData = await response.json();
        setAddModalOpen(responseData.message);
      } else {
        console.error("Failed to create event");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "70vh",
          maxHeight: "70vh",
          overflow: "auto",
        }}
      >
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="editMode" value={true} />
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1-header"
            >
              <Typography>Event Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <TextField
                  label="Event Name"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                  fullWidth
                />
                <Box sx={{ my: 2 }} />
                <TextField
                  label="Event Description"
                  value={eventDescription}
                  onChange={(e) => setEventDescription(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
                <Box sx={{ my: 2 }} />
                <Button onClick={() => setExpanded("panel2")}>Continue</Button>
              </Box>
            </AccordionDetails>
          </Accordion>

          {loading ? (
            <Box
              sx={{
                height: "10vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Loading Games...
            </Box>
          ) : (
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel2-header"
              >
                <Typography>Add Games</Typography>
              </AccordionSummary>

              <AccordionDetails>
                {isAddingNewGame && (
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      label="Home Team Name"
                      value={homeTeamName}
                      onChange={(e) => setHomeTeamName(e.target.value)}
                      fullWidth
                    />
                    <Box sx={{ my: 2 }} />
                    <TextField
                      label="Home Team Short Name"
                      value={homeTeamShortName}
                      onChange={(e) => setHomeTeamShortName(e.target.value)}
                    />
                    <TextField
                      sx={{ ml: "4rem" }}
                      label="Home Score"
                      type="number"
                      required
                      value={homeTeamScore}
                      onChange={(e) => setHomeTeamScore(e.target.value)}
                    />

                    <Box sx={{ my: 2 }} />
                    <TextField
                      label="Away Team Name"
                      value={awayTeamName}
                      onChange={(e) => setAwayTeamName(e.target.value)}
                      fullWidth
                    />
                    <Box sx={{ my: 2 }} />
                    <TextField
                      label="Away Team Short Name"
                      value={awayTeamShortName}
                      onChange={(e) => setAwayTeamShortName(e.target.value)}
                    />
                    <TextField
                      sx={{ ml: "4rem" }}
                      label="Away Team SCORE"
                      type="number"
                      required
                      value={awayTeamScore}
                      onChange={(e) => setAwayTeamScore(e.target.value)}
                    />
                    <Box sx={{ my: 2 }} />

                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "33%" }}>
                        <InputLabel>Event Type:</InputLabel>
                        <Select
                          label="Event Type"
                          value={selectedEventType}
                          onChange={(e) => setSelectedEventType(e.target.value)}
                          fullWidth
                          sx={{ width: "100%" }}
                        >
                          {newGameEventTypes.map((eventType) => (
                            <MenuItem
                              key={eventType.value}
                              value={eventType.value}
                            >
                              {eventType.icon}
                              {` - `}
                              {eventType.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box sx={{ mx: 2, width: "33%" }}>
                        <InputLabel>Winner:</InputLabel>
                        <Select
                          label="Winner"
                          name="winner"
                          value={winner}
                          onChange={(e) => setWinner(e.target.value)}
                          fullWidth
                          sx={{ width: "100%" }}
                        >
                          <MenuItem value="HOME_TEAM">
                            {homeTeamShortName || "Home Team"}
                          </MenuItem>
                          <MenuItem value="AWAY_TEAM">
                            {awayTeamShortName || "Away Team"}
                          </MenuItem>
                        </Select>
                      </Box>
                      <Box
                        sx={{
                          width: "33%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <InputLabel sx={{ mr: 1 }}>Game Date:</InputLabel>
                        <TextField
                          label=""
                          type="date"
                          value={gameDate}
                          required
                          onChange={(e) => setGameDate(e.target.value)}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ my: 2 }} />
                    <Button
                      onClick={() => {
                        const newGame = {
                          // id: Math.random(), // Generate a random and hopefully temporary id for the new game
                          homeTeamName,
                          awayTeamName,
                          homeTeamShortName,
                          awayTeamShortName,
                          winner,
                          gameDate,
                          eventType: selectedEventType,
                          homeTeamScore,
                          awayTeamScore,
                        };
                        setNewGamesList([...newGamesList, newGame]);
                        setIsAddingNewGame(false);
                        setHomeTeamName("");
                        setAwayTeamName("");
                        setHomeTeamShortName("");
                        setAwayTeamShortName("");
                        setGameDate("");
                        setWinner("");
                      }}
                    >
                      Add Game
                    </Button>
                  </Box>
                )}
                <Box sx={{ width: "100%" }}>
                  <Box display="flex" justifyContent="center">
                    <Box m={1}>
                      <IconButton
                        size="large"
                        color={
                          selectedSport === "soccer" ? "secondary" : "default"
                        }
                        onClick={() => filterBySport("soccer")}
                      >
                        <SportsSoccerIcon fontSize="large" />
                      </IconButton>
                    </Box>
                    <Box m={1}>
                      <IconButton
                        size="large"
                        color={
                          selectedSport === "basketball"
                            ? "secondary"
                            : "default"
                        }
                        onClick={() => filterBySport("basketball")}
                      >
                        <BasketballIcon fontSize="large" />
                      </IconButton>
                    </Box>
                    <Box m={1}>
                      <IconButton
                        size="large"
                        color={
                          selectedSport === "football" ? "secondary" : "default"
                        }
                        onClick={() => filterBySport("football")}
                      >
                        <SportsFootball fontSize="large" />
                      </IconButton>
                    </Box>

                    <Box m={1}>
                      <IconButton
                        size="large"
                        color={
                          selectedSport === "wrestling"
                            ? "secondary"
                            : "default"
                        }
                        onClick={() => filterBySport("wrestling")}
                      >
                        <SportsKabaddiIcon fontSize="large" />
                      </IconButton>
                    </Box>

                    <Box m={1}>
                      <IconButton
                        size="large"
                        color={
                          selectedSport === "baseball" ? "secondary" : "default"
                        }
                        onClick={() => filterBySport("baseball")}
                      >
                        <SportsBaseballSharpIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </Box>

                  <Autocomplete
                    size={"small"}
                    value={selectedGame}
                    onChange={(event, newValue) => {
                      setSelectedGame(newValue);
                    }}
                    options={availableGames}
                    getOptionLabel={(option) =>
                      String(
                        `${option.homeTeamName} vs. ${option.awayTeamName}`
                      )
                    }
                    renderOption={(props, option) => (
                      <Box style={{ fontSize: 12 }} {...props}>
                        {`${option.homeTeamName} vs. ${option.awayTeamName}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Choose a game" fullWidth />
                    )}
                  />
                  <Box sx={{ my: 2 }} />
                  <Button onClick={handleAddGame} disabled={!selectedGame}>
                    Add Game
                  </Button>
                  <Button
                    onClick={() => setIsAddingNewGame(!isAddingNewGame)}
                    sx={{ ml: 6 }}
                  >
                    Create A Game
                  </Button>

                  <Box sx={{ my: 2 }} />
                  {gamesList &&
                    gamesList.map((game) => (
                      <Typography
                        key={game.value}
                        variant="subtitle1"
                        gutterBottom
                      >
                        {game.homeTeamName} vs. {game.awayTeamName} -{" "}
                        {game.gameDate}
                      </Typography>
                    ))}

                  <Box sx={{ my: 2 }} />
                  <Typography>Added Games:</Typography>
                  {newGamesList &&
                    newGamesList.map((game) => (
                      <Box>
                        <Typography
                          key={game.value}
                          variant="subtitle1"
                          gutterBottom
                        >
                          {game.homeTeamName} vs. {game.awayTeamName} -{" "}
                          {game.gameDate}
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={() => handleRemoveGame(game)}
                          sx={{ ml: 2 }}
                        >
                          Remove
                        </Button>
                      </Box>
                    ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography>Review</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Event Name:{" "}
                  <Typography variant="subtitle2" color="yellow">
                    {eventName}
                  </Typography>
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Event Description:{" "}
                  <Typography color="yellow" variant="subtitle2">
                    {eventDescription}
                  </Typography>
                </Typography>
                {gamesList && gamesList.length > 0 && (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Games:
                    </Typography>
                    <ul sx={{ fontSize: 18 }}>
                      {gamesList.map((game) => (
                        <li key={game.value}>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: 10 }}
                              color="yellow"
                              gutterBottom
                            >
                              {game.homeTeamName} vs. {game.awayTeamName} -{" "}
                              {game.gameDate}
                            </Typography>
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() => handleRemoveGame(game)}
                              sx={{ ml: 2 }}
                            >
                              Remove
                            </Button>
                          </Box>
                        </li>
                      ))}
                    </ul>
                    <Typography>New Games:</Typography>
                    <ul sx={{ fontSize: 18 }}>
                      {newGamesList.map((game) => (
                        <li key={game.value}>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: 10 }}
                              color="yellow"
                              gutterBottom
                            >
                              {game.homeTeamName} vs. {game.awayTeamName} -{" "}
                              {game.gameDate}
                            </Typography>
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() => handleRemoveGame(game)}
                              sx={{ ml: 2 }}
                            >
                              Remove
                            </Button>
                          </Box>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
                <Box sx={{ mt: 2, flexDirection: "row" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {editMode ? "Update Event" : "Create Event"}
                  </Button>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={eventIsActive}
                        onChange={(event) =>
                          setEventIsActive(event.target.checked)
                        }
                      />
                    }
                    sx={{ ml: 2 }}
                    label="Is Active"
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </form>
      </Box>
    </>
  );
}
