import React, { useState, useEffect } from "react";
import ky from "ky";
import TinderCard from "react-tinder-card";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import "./../App.css";

function About() {

  return (
    <Box sx={{ width: "100%", m: 2, p: 2 }}>
      <Typography variant="p" component="div" gutterBottom>
        Swipe around. <Typography color="yellow" as="span">Have fun.</Typography> That's the law.
      </Typography>

      <Typography variant="p" component="div" gutterBottom>
        The goal of <Typography color="yellow" as="span">Pickery</Typography> is to guess the winners of past sports games. Quickly. Can you break into the top ten?
      </Typography>

      <Typography variant="p" component="div" gutterBottom>
        Try to get that streak, and string together correct answers for <Typography color="yellow" as="span">time boosts</Typography>, but watch out for mistakes that reset your progress!
      </Typography>
    </Box>
  );
}

export default About;
