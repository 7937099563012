import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import Box from "@mui/material/Box";
import toast, { Toaster } from 'react-hot-toast';
import './App.css';
import { storage } from "react-easy-params";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

import Home from "./Screens/Home";
import QuizGame from "./Screens/QuizGame";
import Leaderboards from "./Screens/Leaderboards";
import About from "./Screens/About";
import Admin from "./Screens/Admin";
import Login from "./Components/Login"
import Profile from "./Screens/Profile";
import MenuBar from "./Components/MenuBar";
import Footer from "./Components/Footer";

import ReactGA from "react-ga4";

ReactGA.initialize('G-LWQ6SR8YVX');
ReactGA.pageview(window.location.pathname + window.location.search);

const leaderboardFont =  "'Press Start 2P', cursive";
const monoSpaceFont =  "'VT323', monospace";

let darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    subtitle1: {
      fontFamily: leaderboardFont,
      fontSize: "0.75vw"
    },
    caption: {
      fontFamily: monoSpaceFont,
    },
    input: {
      fontFamily: leaderboardFont,
    },
    "fontFamily": leaderboardFont
  }
});

darkTheme = responsiveFontSizes(darkTheme);

if (!storage.user) {
  storage.user = {
    isAuthenticated: false,
    isAdmin: false,
    uid: null,
    name: null
  }
}

const ProtectedRoute = ({ user, children }) => {
  if (!storage.user.isAuthenticated) {
    toast.error("Oops, you can't go there yet. \n\nTry creating an account!", {
      id: 'error',
    })
    return <Navigate to="/" replace />;
  }
  return children;
};

const AdminRoute = ({ user, children }) => {
  if (!storage.user.isAdmin) {
    toast.error("Oops, you can't go there yet. \n\nTry creating an account!", {
      id: "error",
    });
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={darkTheme}>
        <Router>
          <Box
            sx={{
              bgcolor: "background.default",
              color: "text.primary",
              height: "85vh",
            }}
          >
            <>
              <CssBaseline />
              <MenuBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile/:userID"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route path="/play" element={<QuizGame />} />
                <Route path="/leaderboards" element={<Leaderboards />} />
                <Route path="/about" element={<About />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/admin"
                  element={
                    <AdminRoute>
                      <Admin />
                    </AdminRoute>
                  }
                />
              </Routes>
            </>
          </Box>
        </Router>
        <Box sx={{ height: "5vh" }}>
          <Footer />
        </Box>
        <Toaster />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
