import { store } from "@risingstack/react-easy-state";
import { storage } from "react-easy-params";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import ky from "ky";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;

const appStore = store({
  scoreboard: {
    streak: 0,
    currentStreak: 0,
    currentTripleCount: 0,
    timeExpired: false,
    allotedTime: 10000,
    expire: () => {
      appStore.scoreboard.timeExpired = true;
      appStore.scoreboard.allotedTime = 10000;
      if (appStore.scoreboard.streak > appStore.scoreboard.currentStreak) {
        appStore.scoreboard.currentStreak = appStore.scoreboard.streak;
      }
      window.analytics.track("game_finished", {
        type: "quiz",
        score: appStore.scoreboard.streak,
      });
    },
    reset: () => {
      appStore.currentTripleCount = 0;
      appStore.scoreboard.timeExpired = false;
      appStore.scoreboard.allotedTime = 10000;
      appStore.scoreboard.streak = 0;
      appStore.scoreboard.currentStreak = 0;
      window.analytics.track("submit_score", {
        type: "quiz",
        score: appStore.scoreboard.streak,
      });
    },
    correctToast: () => {
      appStore.scoreboard.streak++;

      if (appStore.scoreboard.streak % 3 === 0) {
        appStore.scoreboard.allotedTime =
          appStore.scoreboard.allotedTime + 3000;
        appStore.scoreboard.currentTripleCount++;

        appStore.scoreboard.displayTimeChange = true;
        appStore.scoreboard.timeChangeNumber = `+3`;
        setTimeout(() => {
          appStore.scoreboard.displayTimeChange = false;
        }, 1000);
      }

      if (appStore.scoreboard.streak > appStore.scoreboard.currentStreak) {
        appStore.scoreboard.currentStreak = appStore.scoreboard.streak;
      }

      appStore.scoreboard.displayCorrect = true;
      setTimeout(() => {
        appStore.scoreboard.displayCorrect = false;
      }, 1000);
    },
    incorrectToast: () => {
      if (appStore.scoreboard.streak > appStore.scoreboard.currentStreak) {
        appStore.scoreboard.currentStreak = appStore.scoreboard.streak;
      }
      appStore.scoreboard.streak = 0;
      appStore.scoreboard.allotedTime = appStore.scoreboard.allotedTime - 1000;

      appStore.scoreboard.displayTimeChange = true;
      appStore.scoreboard.timeChangeNumber = `-1`;
      setTimeout(() => {
        appStore.scoreboard.displayTimeChange = false;
      }, 1000);

      appStore.scoreboard.displayIncorrect = true;
      setTimeout(() => {
        appStore.scoreboard.displayIncorrect = false;
      }, 1000);
    },
    displayCorrect: false,
    displayIncorrect: false,
    displayTimeChange: false,
    timeChangeNumber: "",
  },
  user: {
    signOut: async () => {
      storage.user = {
        isAuthenticated: false,
        isAdmin: false
      };
      firebase.auth().signOut();
      return storage.user.isAuthenticated;
    },
    googleAuth: async () => {
      try {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({ prompt: "select_account" });
        await firebase.auth().signInWithPopup(provider);
        const userCredentials = await firebase.auth().currentUser;

        // const serverAuth = await ky
        //   .post(`${baseUrl}/mobileauth`, {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: bearer,
        //     },
        //     body: JSON.stringify({ authUserId: userCredentials.email }),
        //   })
        //   .json();


        storage.user = {
          isAuthenticated: true,
          isAdmin: userCredentials.email == "bookthesevens@gmail.com",
          uid: userCredentials.uid,
          name: userCredentials.displayName.split(" ")[0],
          // userObject: serverAuth
        };

        return storage.user;
      } catch (e) {
        console.log(e);
      }
    },
  },
});

export default appStore;
