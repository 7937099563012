import React, { useState, useEffect } from "react";
import ky from "ky";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import { useNavigate } from "react-router-dom";
import appStore from "./../utils/appStore";
import { storage } from "react-easy-params";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const bearer = process.env.REACT_APP_API_BEARER;

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Home() {
  let navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", marginTop: 2 }}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Item>
            <Card onClick={() => navigate("/play")}>
              <CardContent>
                <Typography variant="h6" component="div" color="yellow">
                  Play!
                </Typography>
                <Typography variant="p">Swipe right, swipe left to guess. Quickly!</Typography>
              </CardContent>
            </Card>
          </Item>
          <Item>
            <Card sx={{ minWidth: 275 }} onClick={() => navigate("/leaderboards")}>
              <CardContent>
                <Typography variant="h6" color="orange" component="div">
                  Leaderboards
                </Typography>
                <Typography variant="p">
                  See who rules the streaks, and where you rank.
                </Typography>
              </CardContent>
            </Card>
          </Item>
          <Item>
            <Card sx={{ minWidth: 275 }} onClick={() => navigate("/about")}>
              <CardContent>
                <Typography variant="h6" color="hotpink" component="div">
                  About
                </Typography>
                <Typography variant="p">
                  About Pickery
                </Typography>
              </CardContent>
            </Card>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
