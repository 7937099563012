import React from "react";
import TinderCard from "react-tinder-card";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { view } from "@risingstack/react-easy-state";
import { parseISO, format } from "date-fns";
import { deepOrange } from "@mui/material/colors";

import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";

import appStore from "./../utils/appStore";

function EventIcon(type) {
  switch (type.eventType) {
    case "NBA":
      return <SportsBasketballIcon />;
      break;
    case "NFL":
      return <SportsFootballIcon />;
      break;
    default:
      return <SportsSoccerIcon />;
  }
}

function TinderCardDisplay(data) {
  const match = data.data.match;
  const index = data.data.index;

  if (appStore.scoreboard.timeExpired) {
    return null;
  }

  return (
    <TinderCard
      preventSwipe={["up", "down"]}
      className="swipe"
      key={index}
      onSwipe={(dir) => data.parentCallback(dir, match, index)}
      swipeThreshold={1}
      swipeRequirementType={"position"}
    >
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textAlign: "center",
        }}
        className="card"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            height: "33%",
          }}
        >
          <Typography variant="h4" sx={{ color: "common.primary", zIndex: 0 }}>
            {`←`}
          </Typography>

          <Typography variant="p" sx={{ color: "common.primary" }}>
            {match.awayTeamName}
          </Typography>
          <Avatar
            alt={match.awayTeamName}
            src={`./logos/${match.awayTeamShortName
              .toLowerCase()
              .replace(/\s/g, "")}.png`}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "33%",
          }}
        >
          <Avatar sx={{ bgcolor: deepOrange[500] }} >
            <EventIcon eventType={match.eventType}/>
          </Avatar>
          <Typography variant="caption">
            {format(parseISO(match.gameDate), "MMMM dd, yyyy")}
          </Typography>
          <Typography variant="caption">
            {match.eventType}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            p: 1,
            height: "33%",
          }}
        >
          <Avatar
            alt={match.homeTeamName}
            src={`./logos/${match.homeTeamShortName
              .toLowerCase()
              .replace(/\s/g, "")}.png`}
          />
          <Typography variant="p" sx={{ color: "common.primary" }}>
            {match.homeTeamName}
          </Typography>

          <Typography variant="h4" sx={{ color: "common.primary" }}>
            {`→`}
          </Typography>
        </Box>
      </Box>
    </TinderCard>
  );
}

export default view(TinderCardDisplay);
