import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { Shake } from "reshake";

import { styled } from "@mui/material/styles";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ConfettiExplosion from "react-confetti-explosion";

import { view } from "@risingstack/react-easy-state";
import appStore from "./../utils/appStore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const scoreColor = () => {
  if (appStore.scoreboard.displayCorrect) {
    return "yellow";
  } else if (appStore.scoreboard.displayIncorrect) {
    return "red";
  } else {
    return null;
  }
};

const mediumExplodeProps = {
  force: 0.3,
  duration: 1000,
  particleCount: 100,
  particleSize: 10,
  floorHeight: 1200,
  floorWidth: 600,
};

function ScoreBoard() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Item
            sx={{
              height: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="subtitle1" sx={{ fontSize: "4vw" }}>
              SCORE:
            </Typography>
            <Shake
              h={0}
              v={12}
              r={36}
              dur={700}
              int={4.3}
              max={12}
              fixed={true}
              fixedStop={true}
              active={appStore.scoreboard.displayCorrect}
              freez={false}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                color={scoreColor}
                sx={{ fontSize: "4vw" }}
              >
                {appStore.scoreboard.streak}
              </Typography>
            </Shake>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item
            sx={{
              display: "flex",
              justifyContent: "space-around",
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
          <Badge
            badgeContent={appStore.scoreboard.timeChangeNumber}
            color={appStore.scoreboard.displayCorrect ? "success" : "warning"}
            invisible={!appStore.scoreboard.displayTimeChange}
          >
              <CountdownCircleTimer
                strokeWidth={1}
                isPlaying={!appStore.scoreboard.timeExpired}
                key={!appStore.scoreboard.timeExpired}
                size={50}
                onComplete={() => appStore.scoreboard.expire()}
                duration={appStore.scoreboard.allotedTime / 1000}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[10, 7, 5, 1]}
              >
                {({ remainingTime }) => (
                  <Typography>{remainingTime}</Typography>
                )}
              </CountdownCircleTimer>
            </Badge>
            <Box>
              {appStore.scoreboard.displayCorrect && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ConfettiExplosion {...mediumExplodeProps} />
                </Box>
              )}
              <Typography sx={{ fontSize: "1vw", ml: 1 }}>High Score</Typography>
              <Typography
                color={scoreColor}
                sx={{ fontSize: "1vw", ml: 1 }}
              >
                [{appStore.scoreboard.currentStreak}]
              </Typography>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default view(ScoreBoard);
